import {name, version, description} from '../../package.json'

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const SHA = process.env.REACT_APP_SHA || Date.now()
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const SUPPLY_API_ENDPOINT = process.env.REACT_APP_SUPPLY_API_ENDPOINT
export const INSURANCE_API_ENDPOINT = process.env.REACT_APP_INSURANCE_API_ENDPOINT

export const CHAT_THEME = process.env.REACT_APP_CHAT_THEME
export const CHAT_VARIATION = process.env.REACT_APP_CHAT_VARIATION
export const CHAT_STYLE = process.env.REACT_APP_CHAT_STYLE
export const CHAT_HEADER_BACKGROUND_SOURCE = process.env.REACT_APP_CHAT_HEADER_BACKGROUND_SOURCE
export const CHAT_AVATAR_SOURCE = process.env.REACT_APP_CHAT_AVATAR_SOURCE
export const CHAT_AVATAR = process.env.REACT_APP_CHAT_AVATAR
export const CHAT_TITLE = process.env.REACT_APP_CHAT_TITLE
export const CHAT_DESCRIPTION = process.env.REACT_APP_CHAT_DESCRIPTION
export const CHAT_PLACEHOLDER = process.env.REACT_APP_CHAT_PLACEHOLDER
export const CHAT_ROBOT_AVATAR_SOURCE = process.env.REACT_APP_CHAT_ROBOT_AVATAR_SOURCE
export const CHAT_ROBOT_AVATAR = process.env.REACT_APP_CHAT_ROBOT_AVATAR
export const CHAT_USER_AVATAR_SOURCE = process.env.REACT_APP_CHAT_USER_AVATAR_SOURCE
export const CHAT_USER_AVATAR = process.env.REACT_APP_CHAT_USER_AVATAR

export {
  name,
  version,
  description
}
