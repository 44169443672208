import {createReducer} from 're-reducer'

import {prefix, initialState} from './selectors'

const reducer = createReducer({
  prefix,
  initialState
})

export default reducer
