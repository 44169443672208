import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'babel-polyfill'
import 'moment/locale/zh-cn'

import 'index.css'
import App from 'scenes/App'
import {unregister} from 'serviceWorker'

moment.locale('zh-cn')
ReactDOM.render(<App />, document.getElementById('root'))
unregister()
